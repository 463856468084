import React from "react";

const GitLogo = props => {
  let { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 108"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <path
          d="M152.983963,37.213516 C147.387032,37.213516 143.218557,39.9617169 143.218557,46.5756347 C143.218557,51.5588676 145.965589,55.0189589 152.682374,55.0189589 C158.37516,55.0189589 162.242046,51.6640731 162.242046,46.3698995 C162.242046,40.3696804 158.781954,37.213516 152.983963,37.213516 L152.983963,37.213516 L152.983963,37.213516 Z M141.793607,83.9153973 C140.469187,85.5402374 139.149443,87.2679452 139.149443,89.3054247 C139.149443,93.3722009 144.334904,94.5960913 151.458484,94.5960913 C157.359342,94.5960913 165.397041,94.1822831 165.397041,88.6964018 C165.397041,85.435032 161.530155,85.2339726 156.643945,84.9277078 L141.793607,83.9153973 L141.793607,83.9153973 Z M171.906922,37.5209498 C173.73516,39.8611872 175.670941,43.1178813 175.670941,47.7971872 C175.670941,59.089242 166.819653,65.7008219 154.004457,65.7008219 C150.745425,65.7008219 147.794995,65.2951963 145.965589,64.7867032 L142.607196,70.1778995 L152.576,70.7869224 C170.178046,71.9091142 180.551306,72.4187763 180.551306,85.9435251 C180.551306,97.6458813 170.278575,104.255123 152.576,104.255123 C134.162703,104.255123 127.14316,99.5758174 127.14316,91.5392877 C127.14316,86.9605114 129.178301,84.5244201 132.738922,81.1613516 C129.38053,79.7422466 128.263014,77.1997808 128.263014,74.4515799 C128.263014,72.2118721 129.38053,70.1778995 131.214612,68.2432877 C133.045187,66.3133516 135.07916,64.3775708 137.521096,62.1402009 C132.536694,59.6982648 128.771507,54.4075982 128.771507,46.8783927 C128.771507,35.1807123 136.50411,27.1465205 152.066338,27.1465205 C156.441717,27.1465205 159.088219,27.5486393 161.428457,28.1635068 L181.267872,28.1635068 L181.267872,36.8078904 L171.906922,37.5209498 L171.906922,37.5209498 Z"
          fill="#2F2707"
        />
        <path
          d="M199.165662,19.0340091 C193.365333,19.0340091 190.009279,15.6744475 190.009279,9.87294977 C190.009279,4.0796347 193.365333,0.92347032 199.165662,0.92347032 C205.066521,0.92347032 208.423744,4.0796347 208.423744,9.87294977 C208.423744,15.6744475 205.066521,19.0340091 199.165662,19.0340091 L199.165662,19.0340091 L199.165662,19.0340091 Z M186.039525,80.1712511 L186.039525,72.1382283 L191.229662,71.4286758 C192.654612,71.2229406 192.85684,70.9190137 192.85684,69.3900274 L192.85684,39.4801096 C192.85684,38.3637626 192.552913,37.6483653 191.53242,37.3456073 L186.039525,35.4109954 L187.15821,27.1734064 L208.219178,27.1734064 L208.219178,69.3900274 C208.219178,71.0207123 208.31737,71.2229406 209.847525,71.4286758 L215.036493,72.1382283 L215.036493,80.1712511 L186.039525,80.1712511 L186.039525,80.1712511 Z"
          fill="#2F2707"
        />
        <path
          d="M255.267068,76.2272146 C250.890521,78.3617169 244.482338,80.2951598 238.68084,80.2951598 C226.575196,80.2951598 221.998758,75.4171324 221.998758,63.9158356 L221.998758,37.2626119 C221.998758,36.653589 221.998758,36.2456256 221.181662,36.2456256 L214.061589,36.2456256 L214.061589,27.1909406 C223.016913,26.1716164 226.575196,21.6945388 227.693881,10.6058813 L237.359927,10.6058813 L237.359927,25.0564384 C237.359927,25.7659909 237.359927,26.0734247 238.174685,26.0734247 L252.517699,26.0734247 L252.517699,36.2456256 L237.359927,36.2456256 L237.359927,60.5586119 C237.359927,66.5611689 238.786046,68.8990685 244.276603,68.8990685 C247.12884,68.8990685 250.075763,68.188347 252.517699,67.2730594 L255.267068,76.2272146"
          fill="#2F2707"
        />
        <path
          d="M104.528658,49.5295708 L58.0126393,3.01705936 C55.3357443,0.337826484 50.990758,0.337826484 48.3103562,3.01705936 L38.6513242,12.6760913 L50.9042557,24.9290228 C53.7518174,23.9669772 57.0166941,24.6122374 59.2867945,26.8823379 C61.5674155,29.1664658 62.208,32.4593973 61.2225753,35.3163105 L73.0313059,47.1250411 C75.8882192,46.1407854 79.1846575,46.777863 81.4664475,49.0631598 C84.6553425,52.2508858 84.6553425,57.4176438 81.4664475,60.6077078 C78.2763836,63.7977717 73.1096256,63.7977717 69.9183927,60.6077078 C67.5197078,58.2066849 66.9270502,54.6834703 68.141589,51.7283653 L57.1289132,40.7156895 L57.1277443,69.6962922 C57.9050959,70.0820457 58.6391963,70.5952146 59.2867945,71.2404749 C62.4756895,74.4282009 62.4756895,79.5949589 59.2867945,82.7873607 C56.0967306,85.9762557 50.9276347,85.9762557 47.7422466,82.7873607 C44.5533516,79.5949589 44.5533516,74.4282009 47.7422466,71.2404749 C48.5301187,70.4537717 49.4418995,69.8587763 50.4144658,69.4589954 L50.4144658,40.2083653 C49.4418995,39.8109224 48.5312877,39.2194338 47.7422466,38.4268858 C45.3260274,36.0141735 44.7450594,32.4687489 45.9829772,29.5019543 L33.9053881,17.4220274 L2.01059361,49.314484 C-0.669808219,51.9960548 -0.669808219,56.3410411 2.01059361,59.020274 L48.5266119,105.533954 C51.2046758,108.213187 55.5484932,108.213187 58.2300639,105.533954 L104.528658,59.2365297 C107.20789,56.5561279 107.20789,52.2088037 104.528658,49.5295708"
          fill="#DE4C36"
        />
      </g>
    </svg>
  );
};

export default GitLogo;
