import React from "react";

const D3Logo = props => {
  let { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 243"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <linearGradient
          x1="-82.6367258%"
          y1="-92.819878%"
          x2="103.767353%"
          y2="106.041826%"
          id="linearGradient-1"
        >
          <stop stop-color="#F9A03C" offset="0%" />
          <stop stop-color="#F7974E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-258.923825%"
          y1="-248.970263%"
          x2="97.6202479%"
          y2="98.7684937%"
          id="linearGradient-2"
        >
          <stop stop-color="#F9A03C" offset="0%" />
          <stop stop-color="#F7974E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-223.162629%"
          y1="-261.967947%"
          x2="94.0283377%"
          y2="101.690818%"
          id="linearGradient-3"
        >
          <stop stop-color="#F9A03C" offset="0%" />
          <stop stop-color="#F7974E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.3387123%"
          y1="-1.82169774%"
          x2="82.496193%"
          y2="92.1067478%"
          id="linearGradient-4"
        >
          <stop stop-color="#F26D58" offset="0%" />
          <stop stop-color="#F9A03C" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="15.8436473%"
          y1="3.85803114%"
          x2="120.126091%"
          y2="72.3802579%"
          id="linearGradient-5"
        >
          <stop stop-color="#B84E51" offset="0%" />
          <stop stop-color="#F68E48" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="46.9841705%"
          y1="23.4664325%"
          x2="51.881003%"
          y2="147.391179%"
          id="linearGradient-6"
        >
          <stop stop-color="#F9A03C" offset="0%" />
          <stop stop-color="#F7974E" offset="100%" />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M255.52,175.618667 C255.634667,174.504 255.717333,173.378667 255.781333,172.248 C255.858667,170.909333 175.218667,94.3973333 175.218667,94.3973333 L173.290667,94.3973333 C173.290667,94.3973333 255.026667,180.613333 255.52,175.618667 L255.52,175.618667 L255.52,175.618667 Z"
          fill="url(#linearGradient-1)"
        />
        <path
          d="M83.472,149.077333 C83.3653333,149.312 83.2586667,149.546667 83.1493333,149.781333 C83.0346667,150.026667 82.9173333,150.272 82.8,150.514667 C80.2293333,155.874667 118.786667,193.568 121.888,188.989333 C122.029333,188.786667 122.170667,188.573333 122.312,188.370667 C122.469333,188.130667 122.624,187.901333 122.778667,187.661333 C125.258667,183.896 84.5733333,146.629333 83.472,149.077333 L83.472,149.077333 L83.472,149.077333 Z"
          fill="url(#linearGradient-2)"
        />
        <path
          d="M137.957333,202.082667 C137.848,202.322667 137.072,203.634667 136.362667,204.328 C136.242667,204.568 174.002667,242.016 174.002667,242.016 L177.402667,242.016 C177.405333,242.016 141.957333,203.666667 137.957333,202.082667 L137.957333,202.082667 L137.957333,202.082667 Z"
          fill="url(#linearGradient-3)"
        />
        <path
          d="M255.834667,171.568 C254.069333,210.714667 221.682667,242.016 182.114667,242.016 L176.765333,242.016 L137.250667,203.088 C140.501333,198.504 143.522667,193.754667 146.213333,188.802667 L182.114667,188.802667 C193.469333,188.802667 202.709333,179.568 202.709333,168.208 C202.709333,156.853333 193.469333,147.613333 182.114667,147.613333 L160.869333,147.613333 C162.488,139.056 163.373333,130.232 163.373333,121.205333 C163.373333,112.04 162.472,103.090667 160.794667,94.3973333 L173.992,94.3973333 L255.602667,174.810667 C255.698667,173.733333 255.776,172.656 255.834667,171.568 L255.834667,171.568 L255.834667,171.568 Z M21.4666667,0 L0,0 L0,53.2133333 L21.4666667,53.2133333 C58.96,53.2133333 89.4666667,83.712 89.4666667,121.205333 C89.4666667,131.405333 87.192,141.088 83.1493333,149.781333 L122.312,188.370667 C135.170667,169.130667 142.688,146.032 142.688,121.205333 C142.688,54.3733333 88.3066667,0 21.4666667,0 L21.4666667,0 L21.4666667,0 Z"
          fill="url(#linearGradient-4)"
        />
        <path
          d="M182.114667,0 L95.1866667,0 C116.418667,12.9626667 134,31.344 145.978667,53.2133333 L182.114667,53.2133333 C193.469333,53.2133333 202.709333,62.448 202.709333,73.808 C202.709333,85.1653333 193.469333,94.4 182.114667,94.4 L173.994667,94.4 L255.605333,174.813333 C255.797333,172.632 255.917333,170.437333 255.917333,168.208 C255.917333,150.269333 249.48,133.813333 238.792,121.005333 C249.48,108.202667 255.917333,91.744 255.917333,73.808 C255.917333,33.112 222.813333,0 182.114667,0 L182.114667,0 L182.114667,0 Z"
          fill="url(#linearGradient-5)"
        />
        <path
          d="M176.765333,242.016 L95.808,242.016 C112.104,231.952 126.192,218.666667 137.250667,203.088 L176.765333,242.016 L176.765333,242.016 Z M122.312,188.370667 L83.152,149.781333 C72.3333333,173.032 48.7573333,189.202667 21.4666667,189.202667 L0,189.202667 L0,242.410667 L21.4666667,242.410667 C63.4773333,242.410667 100.557333,220.922667 122.312,188.370667 L122.312,188.370667 L122.312,188.370667 Z"
          fill="url(#linearGradient-6)"
        />
      </g>
    </svg>
  );
};

export default D3Logo;
