import React from "react";

const AngularLogo = props => {
  let { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 270"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(0.000000, -1.000000)">
        <path
          d="M127.606036,1.34131737 L0.849245509,45.9497006 L20.8800958,212.021653 L127.740934,270.754491 L235.152096,211.22606 L255.175281,45.1602395 L127.606036,1.34131737 Z"
          fill="#B3B3B3"
        />
        <path
          d="M242.531641,54.7579401 L127.31018,15.4657725 L127.31018,256.722012 L223.871234,203.280862 L242.531641,54.7579401 Z"
          fill="#A6120D"
        />
        <path
          d="M15.0733413,55.4661557 L32.2376048,203.990611 L127.308647,256.722012 L127.308647,15.4611737 L15.0733413,55.4661557 Z"
          fill="#DD1B16"
        />
        <path
          d="M159.026587,143.89806 L127.31018,158.729198 L93.881485,158.729198 L78.1673772,198.033629 L48.9389222,198.574754 L127.31018,24.226491 L159.026587,143.89806 L159.026587,143.89806 Z M155.960719,136.431138 L127.520192,80.128 L104.192,135.462323 L127.308647,135.462323 L155.960719,136.431138 L155.960719,136.431138 Z"
          fill="#F2F2F2"
        />
        <path
          d="M127.308647,24.226491 L127.518659,80.128 L153.989365,135.505246 L127.368431,135.505246 L127.308647,158.69394 L164.118994,158.729198 L181.323114,198.580886 L209.289964,199.099018 L127.308647,24.226491 Z"
          fill="#B3B3B3"
        />
      </g>
    </svg>
  );
};

export default AngularLogo;
