import React from "react";

const CPlusPlusLogo = props => {
  let { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 243"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <path
          d="M255.569,84.72 C255.567,79.89 254.534,75.622 252.445,71.959 C250.393,68.357 247.32,65.338 243.198,62.951 C209.173,43.332 175.115,23.773 141.101,4.134 C131.931,-1.16 123.04,-0.967 113.938,4.403 C100.395,12.39 32.59,51.237 12.385,62.94 C4.064,67.757 0.015,75.129 0.013,84.711 C0,124.166 0.013,163.62 0,203.076 C0.002,207.8 0.991,211.985 2.988,215.593 C5.041,219.304 8.157,222.406 12.374,224.847 C32.58,236.55 100.394,275.394 113.934,283.383 C123.04,288.756 131.931,288.948 141.104,283.652 C175.119,264.012 209.179,244.454 243.209,224.835 C247.426,222.395 250.542,219.291 252.595,215.583 C254.589,211.975 255.58,207.79 255.582,203.065 C255.582,203.065 255.582,124.176 255.569,84.72"
          fill="#5C8DBC"
        />
        <path
          d="M128.182,143.509 L2.988,215.593 C5.041,219.304 8.157,222.406 12.374,224.847 C32.58,236.55 100.394,275.394 113.934,283.383 C123.04,288.756 131.931,288.948 141.104,283.652 C175.119,264.012 209.179,244.454 243.209,224.835 C247.426,222.395 250.542,219.291 252.595,215.583 L128.182,143.509"
          fill="#1A4674"
        />
        <path
          d="M91.101,164.861 C98.386,177.579 112.081,186.157 127.791,186.157 C143.598,186.157 157.371,177.47 164.619,164.616 L128.182,143.509 L91.101,164.861"
          fill="#1A4674"
        />
        <path
          d="M255.569,84.72 C255.567,79.89 254.534,75.622 252.445,71.959 L128.182,143.509 L252.595,215.583 C254.589,211.975 255.58,207.79 255.582,203.065 C255.582,203.065 255.582,124.176 255.569,84.72"
          fill="#1B598E"
        />
        <path
          d="M248.728,148.661 L239.006,148.661 L239.006,158.385 L229.282,158.385 L229.282,148.661 L219.561,148.661 L219.561,138.94 L229.282,138.94 L229.282,129.218 L239.006,129.218 L239.006,138.94 L248.728,138.94 L248.728,148.661"
          fill="#FFFFFF"
        />
        <path
          d="M213.253,148.661 L203.532,148.661 L203.532,158.385 L193.81,158.385 L193.81,148.661 L184.088,148.661 L184.088,138.94 L193.81,138.94 L193.81,129.218 L203.532,129.218 L203.532,138.94 L213.253,138.94 L213.253,148.661"
          fill="#FFFFFF"
        />
        <path
          d="M164.619,164.616 C157.371,177.47 143.598,186.157 127.791,186.157 C112.081,186.157 98.386,177.579 91.101,164.861 C87.562,158.681 85.527,151.526 85.527,143.893 C85.527,120.552 104.45,101.63 127.791,101.63 C143.4,101.63 157.023,110.101 164.344,122.689 L201.285,101.417 C186.602,76.071 159.189,59.019 127.791,59.019 C80.915,59.019 42.916,97.019 42.916,143.893 C42.916,159.271 47.007,173.692 54.157,186.131 C68.803,211.611 96.294,228.768 127.791,228.768 C159.346,228.768 186.88,211.542 201.505,185.987 L164.619,164.616"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
};

export default CPlusPlusLogo;
