import React from "react";

import TopAppBar from "../TopAppBar/TopAppBar";
import IntroPage from "../IntroPage/IntroPage";
import SkillSet from "../SkillSet/SkillSet";
import Contact from "../Contact/Contact";
import Work from "../Work/Work";

class Ensemble extends React.Component {
  render() {
    return <div>{/* <TopAppBar /> */}</div>;
  }
}

export default Ensemble;
